export const targetListRight = [
  { label: '广告请求', value: 'advRequest', color: '#ffab31' },
  { label: '广告填充', value: 'advResponse', color: 'rgb(76, 180, 231)' },
  { label: '广告填充率', value: 'advRequestFilledRate', color: '#de7af9' },
  { label: '收入eCPM', value: 'advEcpm', color: 'rgb(0, 118, 143)' },
  { label: '成交eCPM', value: 'ecpm', color: 'rgb(0, 118, 143)' },
  { label: '竞胜率', value: 'bidSuccessRate', color: 'rgb(0, 118, 143)' },
  { label: '点击', value: 'click', color: '#ff746c' },
  { label: '点击率', value: 'clickRate', color: '#ff4400' },
  { label: '预估消耗', value: 'income', color: '#39da61' },
  { label: '利润', value: 'profit', color: 'rgb(145, 78, 0)' },
  { label: '利润率', value: 'profitRate', color: '#ff6688' }
]

export const targetListLeft = [
  { label: '流量请求', value: 'request', color: '#ffab31' },
  { label: '流量填充', value: 'response', color: 'rgb(76, 180, 231)' },
  { label: '流量填充率', value: 'requestFilledRate', color: '#de7af9' },
  { label: '收入eCPM', value: 'advEcpm', color: '#de7af9' },
  { label: '成交eCPM', value: 'eCPM', color: 'rgb(0, 118, 143)' },
  { label: '展示', value: 'impress', color: 'rgb(0, 118, 143)' },
  { label: '展示率', value: 'impressRate', color: '#de7af9' },
  { label: '点击', value: 'click', color: '#ff746c' },
  { label: '点击率', value: 'clickRate', color: '#ff4400' },
  { label: '流量消耗', value: 'income', color: '#39da61' },
  { label: '利润', value: 'profit', color: 'rgb(145, 78, 0)' },
  { label: '利润率', value: 'profitRate', color: '#ff6688' }
]
