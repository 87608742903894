<template>
  <div class="home_title" :style="{'font-size': fontSize + 'px'}">
    <span class="title" :style="{ borderColor: '#6554C0' }">{{ title }}</span>
  </div>
</template>

<script>
import autoFontSize from '@/mixins/autoFontSize'
export default {
  mixins: [autoFontSize],
  props: {
    title: {
      default: '',
      type: String
    },
    borderColor: {
      default: '#fff',
      type: String
    }
  }
}
</script>

<style lang="less" scoped>
.home_title {
  box-sizing: border-box;
  position: relative;
  font-family: PingFang SC, PingFang SC;
  .title {
    font-size: calc(16em / 14);
    font-weight: 600;
    border-radius: calc(10em / 14);
    position: relative;
    cursor: pointer;
    color: #344563;
    margin-right: calc(4em / 14);
    &::after {
      content: '';
      width: calc(5em / 14);
      height: calc(10em / 14);
      background: #6554C0;
      position: absolute;
      top: calc(5em / 14);
      left: calc(-10em / 14);
      border-radius: calc(99em / 14);
    }
  }
}
</style>
